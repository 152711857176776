// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qx d_fp d_bG d_dv";
export var alignLeft = "p_qp d_fp d_bG d_dv";
export var alignDiscCenter = "p_qy d_fq d_bD d_dw";
export var alignCenter = "p_bP d_fq d_bD d_dw";
export var alignDiscRight = "p_qz d_fr d_bH d_dx";
export var alignRight = "p_qq d_fr d_bH d_dx";
export var footerContainer = "p_qB d_dW d_bW";
export var footerContainerFull = "p_qC d_dT d_bW";
export var footerHeader = "p_kf d_kf";
export var footerTextWrapper = "p_qD d_w";
export var footerDisclaimerWrapper = "p_km d_km d_ck";
export var badgeWrapper = "p_qF d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "p_qG d_bz d_bJ d_bN d_bL";
export var wide = "p_qH d_cy";
export var right = "p_qJ d_bK";
export var line = "p_fk d_fl d_cv";
export var badgeDisclaimer = "p_qK d_bC d_bP d_bJ";
export var badgeContainer = "p_qL d_bz d_bH d_bP";
export var badge = "p_qM";
export var padding = "p_qN d_c7";
export var end = "p_qP d_bH";
export var linkWrapper = "p_qQ d_dB";
export var link = "p_mC d_dB";
export var colWrapper = "p_qR d_cx";
export var consent = "p_f d_f d_bC d_bP";
export var disclaimer = "p_qS d_bz d_bJ";
export var media = "p_qT d_bx d_dy";
export var itemRight = "p_qV";
export var itemLeft = "p_qW";
export var itemCenter = "p_qX";
export var exceptionWeight = "p_qY t_rV";