// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qZ d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_q0 d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_q1 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_q2 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_q3 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_q4 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_q5 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_q6 t_q6";
export var heroExceptionNormal = "q_q7 t_q7";
export var heroExceptionLarge = "q_q8 t_q8";
export var Title1Small = "q_q9 t_q9 t_rs t_rt";
export var Title1Normal = "q_rb t_rb t_rs t_rv";
export var Title1Large = "q_rc t_rc t_rs t_rw";
export var BodySmall = "q_rd t_rd t_rs t_rM";
export var BodyNormal = "q_rf t_rf t_rs t_rN";
export var BodyLarge = "q_rg t_rg t_rs t_rP";