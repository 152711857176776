// extracted by mini-css-extract-plugin
export var customText = "m_qh d_dv d_cs d_cg";
export var videoIframeStyle = "m_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "m_qj d_cs d_cg d_Z";
export var customRow = "m_qb d_bD d_bf";
export var quoteWrapper = "m_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "m_pX d_H";
export var masonryImageWrapper = "m_p3";
export var title = "m_qk";
export var Title3Small = "m_ql t_ql t_rs t_rB";
export var Title3Normal = "m_qm t_qm t_rs t_rC";
export var Title3Large = "m_qn t_qn t_rs t_rD";