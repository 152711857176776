// extracted by mini-css-extract-plugin
export var alignLeft = "r_qp d_bG";
export var alignCenter = "r_bP d_bD";
export var alignRight = "r_qq d_bH";
export var textAlignLeft = "r_rh";
export var textAlignCenter = "r_rj";
export var textAlignRight = "r_rk";
export var hoursInnerWrapperAlt = "r_rl d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "r_rm d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "r_rn d_dw";
export var titleMargin = "r_rp d_cw";
export var hoursInnerInnerWrapper = "r_rq d_cz";