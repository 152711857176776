// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "v_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "v_fR d_fR d_bz d_bJ";
export var menuDesign6 = "v_st d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "v_sv d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "v_sw d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "v_sx d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "v_sy d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "v_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "v_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "v_sz";
export var navbarItem = "v_nb d_bx";
export var navbarLogoItemWrapper = "v_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "v_sB d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "v_sC d_gd d_by d_Z d_bs";
export var burgerToggle = "v_sD d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "v_sF d_gd d_by d_Z d_bs";
export var burgerInput = "v_sG d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "v_sH d_f3 d_w d_H";
export var burgerLine = "v_sJ d_f1";
export var burgerMenuDesign6 = "v_sK d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "v_sL d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "v_sM d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "v_sN d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "v_sP d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "v_sQ d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "v_sR d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "v_sS d_bC d_bP";
export var compact = "v_sT";
export var navDivided = "v_sV";
export var staticBurger = "v_sW";
export var menu = "v_sX";
export var navbarDividedLogo = "v_sY";
export var nav = "v_sZ";